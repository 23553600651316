import React from 'react'
import User from './components/Admin/Users/User'
import Games from './components/Games/Games'
import GetUsersPagination from './components/Admin/Users/GetusersPagination'
import MainUsersPage from './components/Admin/Users/MainUsersPage'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Getusers = React.lazy(() => import('./components/Admin/Users/Getusers'))
const category = React.lazy(() => import('./components/category/category'))
const listword = React.lazy(() => import('./components/listword/ListOfWords'))
const Admin = React.lazy(() => import('./components/Admin/Admin'))
const CustomAi = React.lazy(() => import('./views/customAi/CustomAi'))
const CustomCategory = React.lazy(() => import('./views/customAi/customCategory/CustomCategory'))
//manageEnterprise

const ManageEnterprise = React.lazy(() => import('./views/manageEnterprise/manageEnterprise'))
const Charts = React.lazy(() => import('./views/charts/Charts'))
const analytics = React.lazy(() => import('./components/Analytics/Analyticsmain.js'))
const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/analytics', name: 'Analytics', element: analytics },
  { path: '/GetusersPagination', name: 'Users', element: Getusers },
  { path: '/users', name: 'Users', element: MainUsersPage },

  { path: '/user/:id', element: User },
  { path: '/games', element: Games },
  { path: '/custom-ai', element: CustomAi },
  { path: '/custom-ai/category', name: 'Category', element: CustomCategory },
  { path: '/topic', element: category },
  { path: '/words', element: listword },
  { path: '/manage-word', name: 'Manage Word', element: Admin },
  { path: '/charts', name: 'Charts', element: Charts },
  { path: '/widgets', name: 'Widgets', element: Widgets },
  { path: '/manage-enterprise', name: 'Manage Enterprise', element: ManageEnterprise },
]

export default routes
