import React, { useState } from 'react'
import GetInactiveUsers from './GetInactiveUsers'
import GetUsersPagination from './GetusersPagination'
import '../../../views/manageEnterprise/manageEnterprise.css'

const MainUsersPage = () => {
  const [activeButton, setActiveButton] = useState('Active Users')
  
  return (
    <div style={{ 
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    }}>
      <div style={{ 
        marginTop: '15px',
        paddingBottom: '10px',
        borderBottom: '1px solid #e1e4e8'
      }}>
        <h2 style={{
          fontSize: '24px',
          fontWeight: '600',
          color: '#333',
          marginBottom: '20px',
          textAlign: 'center'
        }}>
          Users List
        </h2>
        
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '30px',
          marginBottom: '30px',
          position: 'relative',
          zIndex: '1'
        }}>
          <div
            onClick={() => setActiveButton('Active Users')}
            className={
              activeButton === 'Active Users'
                ? 'zoundslike_enterprise_words_button_active_class_syn'
                : 'zoundslike_enterprise_words_button_syn'
            }
            style={{ 
              cursor: 'pointer',
              backgroundColor: activeButton !== 'Active Users' && "#f2f2f2",
              padding: '12px 25px',
              borderRadius: '25px',
              fontSize: '16px',
              fontWeight: '500',
              transition: 'all 0.3s ease',
              color: activeButton === 'Active Users' ? "#fff" : "#000",
              boxShadow:  '0 4px 8px rgba(0, 0, 0, 0.2)' 
            }}
          >
            Active Users
          </div>
          <div
            onClick={() => setActiveButton('InActive Users')}
            className={
              activeButton === 'InActive Users'
                ? 'zoundslike_enterprise_words_button_active_class_syn'
                : 'zoundslike_enterprise_words_button_syn'
            }
            style={{ 
              cursor: 'pointer',
              padding: '12px 25px',
              borderRadius: '25px',
              fontSize: '16px',
              fontWeight: '500',
              backgroundColor: activeButton !== 'InActive Users' && "#f2f2f2",
              transition: 'all 0.3s ease',
              color: activeButton === 'InActive Users' ? "#fff" : "#000",
              boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)'
            }}
          >
            Inactive Users
          </div>
        </div>
        
        <div 
          className="zoundslike_admin_words_crud_syn"
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '25px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            minHeight: '400px'
          }}
        >
          {activeButton === 'Active Users' && <GetUsersPagination />}
          {activeButton === 'InActive Users' && <GetInactiveUsers />}
        </div>
      </div>
    </div>
  )
}

export default MainUsersPage